<template>
    <div class="law-detail">
        <div class="lawDetail-content">
            <div class="lawDetail-top module">
                <img :src="accidentLevel[obj.accidentLevel]" alt="" class="hazard-img-typ" v-if="obj.accidentLevel!=5">
                <p class="title">事故信息</p>
                <div class="item-line pt0">
                    <p class="accidentName">{{obj.accidentName}}</p>
                    <div class="accident-base-info">
                        <div class="accident-base-info-left">
                            <p>事故发生时间：{{$dayjs(obj.accidentTime).format('YYYY年MM月DD日')}}</p>
                            <p>国内/国外事故：{{obj.domesticOrInternational==1?'国内':'国外'}}</p>
                            <p><span>事故发生地点：</span><span>{{obj.accidentLocation}}</span></p>
                        </div>
                        <div class="accident-base-info-right">
                            <p>事故类型：{{getText(obj.accidentType1)?.label}} > {{getText(obj.accidentType2)?.label}}</p>
                            <p><span>次生衍生事故：</span><span>{{obj.secondaryIncident || '--'}}</span></p>
                        </div>
                    </div>
                </div>
                <div class="item-line item-inline-flex" v-if="obj.casualtyCount!=null || obj.minorInjuryCount!=null || obj.seriousInjuryCount!=null  || obj.directEconomicLoss!=null || obj.indirectEconomicLoss!=null">
                    <div class="item-line-left" v-if="obj.casualtyCount!=null||obj.minorInjuryCount!=null||obj.seriousInjuryCount!=null">
                        <p class="sub">伤亡情况</p>
                        <p>
                            <span>死亡人数：{{ obj.casualtyCount!=null?obj.casualtyCount:'--' }}{{ obj.casualtyCount!=null?'人':'' }}</span>
                            <span>轻伤人数：{{ obj.minorInjuryCount!=null?obj.minorInjuryCount:'--' }}{{ obj.minorInjuryCount!=null?'人':'' }}</span>
                            <span>重伤人数：{{ obj.seriousInjuryCount!=null?obj.seriousInjuryCount:'--' }}{{ obj.seriousInjuryCount!=null?'人':'' }}</span>
                        </p>
                    </div>
                    <div class="item-line-right" v-if="obj.directEconomicLoss!='' || obj.indirectEconomicLoss!=''">
                        <p class="sub">经济损失情况</p>
                        <p>
                            <span>直接经济损失：{{obj.directEconomicLoss || 0 || '--'}}{{ obj.directEconomicLoss!=''?'万元':'' }}</span>
                            <span>间接经济损失：{{obj.indirectEconomicLoss || 0 || '--'}}{{ obj.indirectEconomicLoss!=''?'万元':'' }}</span>
                        </p>
                    </div>
                </div>
                <div class="item-line" v-if="obj.eventVideo">
                    <p class="sub sub-title">事故视频：</p>
                    <div class="video-box">
                        <video :src="obj.eventVideo" controls></video>
                    </div>
                </div>
                <div class="item-line" v-if="obj.scenePhotoList && obj.scenePhotoList.length">
                    <p class="sub sub-title">现场照片：</p>
                    <div>
                        <el-image
                            v-for="(item,index) in obj.scenePhotoList"
                            :key="index+'image'"
                            fit="contain"
                            style="width: 206px; height: 115px"
                            :src="item" 
                            :preview-src-list="obj.scenePhotoList">
                        </el-image>
                    </div>
                </div>
            </div>
            <div class="lawDetail-middle module" v-if="obj.directCauseOfAccident || obj.indirectCauseOfAccident || obj.accidentInvestigationReport">
                <p class="title">事故分析</p>
                <div class="item-line pt0" v-if="obj.directCauseOfAccident">
                    <p class="sub">事故直接原因：</p>
                    <p class="desc">{{ obj.directCauseOfAccident }}</p>
                </div>
                <div class="item-line" v-if="obj.indirectCauseOfAccident">
                    <p class="sub">事故间接原因：</p>
                    <p class="desc">{{ obj.indirectCauseOfAccident }}</p>
                </div>
                <div class="item-line" v-if="obj.accidentInvestigationReport">
                    <p class="sub">事故调查报告：</p>
                    <div class="report-box" @click="downLoad(obj)">
                        <p>
                            <svg-icon iconClass="kbase-doc"></svg-icon>
                            <span class="report">事故报告{{prev}}</span>
                        </p>
                        <i class="el-icon-download"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {accidentType} from './../kbase.data'
export default{
    data(){
        return{
            accidentType,
            obj:{},
            prev:'',
            accidentLevel:{
                1:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/kbase/accident-type1.png',
                2:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/kbase/accident-type2.png',
                3:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/kbase/accident-type3.png',
                4:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/kbase/accident-type4.png'
            }
        }
    },
    created(){
        this.getDetail(); 
    },
    methods:{
        getDetail(){
            console.log(this.$route.query.caseId);
            this.$api.kbase.getCaseDetail({
                caseId:this.$route.query.caseId
            }).then(res=>{
                if(res.data){
                    this.obj = res.data;
                    const docUrl = res.data.accidentInvestigationReport;
                    if(docUrl){
                        const index = docUrl.lastIndexOf('.');
                        this.prev = docUrl.slice(index,docUrl.length);
                    }
                }
            })
        },
        getText(params){
            const obj = this.treeFindIdPath(
                this.accidentType,
                (data) => data.value == params
            );
            return obj
        },
        treeFindIdPath(tree, func, path = {}) {
            if (!tree) return {};
            for (const data of tree) {
                path = Object.assign({}, data);
                if (func(data)) return path;
                if (data.children) {
                    const findChildren = this.treeFindIdPath(data.children, func, path);
                    if (func(findChildren)) return findChildren;
                }
                path = {};
            }
            return path;
        },
        downLoad(item){
            const that = this;
            var xhr = new XMLHttpRequest();
            xhr.open("GET", item.accidentInvestigationReport, true);
            xhr.responseType = "blob";
            xhr.onload = function (e) {
                if (e.target.readyState === 4 && e.target.status === 200) {
                    let blob = this.response;
                    // console.debug("blob", blob);
                    // 转换一个blob链接
                    let u = window.URL.createObjectURL(blob);
                    // console.debug(u);
                    let a = document.createElement("a");
                    a.download = `事故报告${that.prev}`;
                    a.href = u;
                    a.style.display = "none";
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(u);
                }
            };
            xhr.send();
        },
    }
}
</script>
<style lang="scss" scoped>
.video-box{
    margin-top: 24px;
    width: 556px;
    height: 313px;
    video{
        width: 556px;
        height: 313px;
        background: rgba(0,0,0,.5);
    }
}
.report-box{
    width: 320px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(230,0,3,0.04);
    border-radius: 8px;
    padding: 16px;
    box-sizing: border-box;
    margin-top: 24px;
    cursor: pointer;
    transition: all .5s ease-in-out;
    &:hover{
        background: rgba(230,0,3,0.08);
    }
    .icon{
        font-size: 32px;
        margin-right: 8px;
    }
    p{
        display: flex;
        align-items: center;
    }
    .report{
        font-size: 14px;
        line-height: 32px;
        color: #666;
    }
    ::v-deep .el-icon-download{
        color: #E60003;
        font-size: 18px;
    }
}
::v-deep .el-image{
    margin-right: 24px;
    margin-top: 16px;
    background: rgba(0,0,0,.5);
    &:nth-child(5n){
        margin-right: 0;
    }
}
.law-detail{
    min-height: 100vh;
    box-sizing: border-box;
    background: #F3F3F3;
    padding-bottom: 24px;
}
.lawDetail-middle{
    p{
        font-size: 18px;
        line-height: 30px;
    }
}
.lawDetail-content{
    overflow: hidden;
    .lawDetail-top{
        position: relative;
    }
    .accidentName{
        font-size: 22px;
        line-height: 34px;
        font-weight: 600;
        color: #333;
        padding-right: 196px;
    }
    .item-inline-flex{
        display: flex;
        .sub{
            color: #333;
            font-weight: 600;
            font-size: 22px;
            line-height: 22px;
            margin-bottom: 24px;
        }
        .item-line-left{
            width:50%;
        }
        .item-line-right{
            width:50%;
        }
        span{
            color: #666;
            font-size: 18px;
            margin-right: 48px;

        }
    }
    .accident-base-info{
        display: flex;
        p{
            margin-top: 12px;
            display: flex;
            span:nth-child(1){
                white-space: nowrap;
            }
        }
        p,span{
            font-size: 18px;
            line-height: 30px;
            color: #666;
        }
        .accident-base-info-left{
            width:50%;
            padding-right: 32px;
            box-sizing: border-box;
        }
        .accident-base-info-right{
            width:50%;
            box-sizing: border-box;
        }
    }
    .hazard-img-typ{
        position: absolute;
        right: 32px;
        top: 0;
        width: 159px;
    }
    .module{
        background: #fff;
        border-radius: 8px;
        padding:24px 24px 32px;
        width:1200px;
        margin:24px auto;
        box-sizing: border-box;
    }
    .name{
        font-size: 18px;
        line-height: 30px;
        margin-bottom: 32px;
        color: #333;
    }
    .item-line{
        border-bottom: 1px solid #E9E9E9;
        padding: 24px 0;
        margin: 0 13px;
        &:nth-last-child(1){
            border-bottom: none;
            padding-bottom: 0;
        }
    }
    .pt0{
        padding-top: 0;
    }
    .bordernone{
        border-bottom: none;
        padding-bottom: 0;
    }
    .desc-type{
        color: #333;
        font-size: 18px;
    }
    .sub{
        font-size: 18px;
        line-height: 18px;
        color: #666;
    }
    .sub-title{
        font-size: 22px;
        line-height: 22px;
        font-weight: 600;
        color: #333;
    }
    .desc{
        margin-top: 16px;
        background: #F2F2F2;
        color: #333;
        font-size: 18px;
        line-height: 30px;
        padding: 16px;
        border-radius: 8px;
    }
    .title{
        font-size: 24px;
        line-height: 24px;
        font-weight: 600;
        color: #333;
        position: relative;
        padding-left: 13px;
        margin-bottom: 30px;
        &::after{
            content: '';
            width: 5px;
            height: 20px;
            background: #E60003;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}
</style>