<template>
    <div class="law">
        <div class="kbase-banner">
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/kbase/accident-bg.png" alt="">
        </div>
        <div class="law-container">
            <div class="law-search">
                <div class="law-category">
                    <div class="law-category-header">
                        <span v-for="item in accidentType" :key="item.value" :class="{active:accidentType1==item.value}" @click="changeType(item.value)">{{item.label}}</span>
                    </div>
                    <div class="law-category-list">
                        <span v-for="item in accidentType[ accidentType1 -1 ].children" :key="item.value" @click="changeCategory(item)" :class="accidentType2==item.value?'active':''">
                            {{ item.label }}
                        </span>
                    </div>
                </div>
                <el-form ref="searchForm" :model="searchForm" :inline="true">
                    <el-form-item label="" prop="accidentName">
                        <el-input v-model="searchForm.accidentName" placeholder="请输入事故名称" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="" prop="accidentLevel">
                        <el-select v-model="searchForm.accidentLevel" placeholder="请选择事故级别" @change="search" clearable popper-class = " dxfSelect ">
                            <el-option
                                v-for="(value,key) in accidentCls"
                                :key="key"
                                :label="value.text"
                                :value="key">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="search">查询</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <ul class="lawList" v-if="list && list.length>0">
                <li v-for="item in list" :key="item.caseId" @click="handleDetail(item)">
                    <div class="top">
                        <img :src="item.scenePhotoList && item.scenePhotoList[0]" alt="" v-if="item.scenePhotoList && item.scenePhotoList[0]">
                        <template v-else>
                            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-h5/case-default.png" alt="">
                            <p>{{accidentType[accidentType1-1].label}}事故</p>
                        </template>
                    </div>
                    <div class="bottom">
                        <p class="title">{{ item.accidentName }}</p>
                        <p class="time">事故发生时间：{{$dayjs(item.accidentTime).format('YYYY年MM月DD日')}}</p>
                    </div>
                    <span class="tag" :class="accidentCls[item.accidentLevel]?.cls">{{ accidentCls[item.accidentLevel]?.text }}</span>
                </li>
            </ul>
            <div class="pagination" v-if="list && list.length>0">
                <el-pagination @current-change="handleCurrentChange"
                    :current-page.sync="paging.pageNum" :page-size="paging.pageSize" layout="total,prev, pager, next, jumper"
                    :total="total">
                </el-pagination>
            </div>
            <empty-data text="暂无更多数据" v-if="list && list.length==0"></empty-data>
        </div>
    </div>
</template>
<script>
import emptyData from '../../../layout/empty-data.vue';
import {accidentType,accidentCls} from './../kbase.data.js'
export default{
  components: { emptyData },
    data(){
        return{
            accidentType1:1,
            accidentType2:101,
            accidentType,
            accidentCls,
            searchForm:{
                accidentName:'',
                accidentLevel:'',
            },
            total: 0,
            list:null,
             paging:{
                pageNum: 1,
                pageSize: 12,
             }
        }
    },
    created(){
        this.getData();
    },
    methods:{
        changeType(type){
            this.accidentType1 = type;
            this.accidentType2 = this.accidentType[this.accidentType1-1].children[0]?.value;
            this.search();
        },
        changeCategory(item){
            this.accidentType2 = item.value;
            this.search();
        },
        handleDetail(item) {
           this.$router.push({
                path: "/accident/detail",
                query: {
                    caseId: item.caseId,
                },
            });
        },
        search(){
            this.paging.pageNum = 1;
            this.getData();
        },
        getData(){
            const params = {
                hazardDesc:this.hazardDesc,
                accidentType1:this.accidentType1,
                accidentType2:this.accidentType2,
                ...this.paging,
                ...this.searchForm
            }
            this.$api.kbase.caseList(params).then(res=>{
                if(res.code==0){
                    this.list = res.data.list;
                    this.total = res.data.total;
                }
            })
        },
        handleCurrentChange(val) {
            this.paging.pageNum = val;
            this.getData();
        }
    }
}
</script>
<style lang="scss" scoped>
.law{
    background: #F3F3F3;
    min-height: 100vh;
    padding-bottom: 48px;
    ::v-deep .wd-empty-data{
        padding-top: 148px;
        padding-bottom: 148px;
    }
    .kbase-banner{
        min-width: 1200px;
        img{
            width:100%;
        }
    }
}
.law-container{
    overflow: hidden;
    width:1200px;
    margin:0 auto;
}
.dxfSelect{
    .el-select-dropdown__item.hover, .el-select-dropdown__item:hover{
        background: rgba(255, 228, 228,.5);
    }
    .el-select-dropdown__item.selected{
        color: #E60003;
    }
}
.law-search{
    background: #fff;
    padding: 24px;
    margin:24px 0;
    border-radius: 4px;
    ::v-deep .el-form{
        margin-top: 12px;
    }
    ::v-deep {
        .el-input__inner{
            border-color: #d8d8d8;
        }
        .el-select .el-input.is-focus .el-input__inner,
        .el-select .el-input__inner:focus{
            border-color: #E60003;
        }
        .el-input__inner:focus{
            border-color: #E60003;
        }
        .el-form-item__content{
            width: 292px;
        }
        .el-select{
            width: 100%;
        }
        .el-form-item{
            margin-right: 40px;
            margin-bottom: 0;
            &:nth-last-child(1){
                margin-right: 0;
                .el-form-item__content{
                    width: 156px;
                }
                button{
                    width: 156px;
                    background: #E60003;
                    border-color: #E60003;
                    span{
                        font-size: 16px;
                    }
                    &:hover{
                        opacity: .8;
                    }
                }
            }
        }
    }
}
.law-category-list{
    margin-top: 16px;
}
.law-category-list span{
    font-size: 16px;
    color: #666;
    line-height: 16px;
    margin-right: 24px;
    padding: 8px 14px;
    border-radius: 4px;
    display: inline-block;
    margin-bottom: 16px;
    cursor: pointer;
    &:hover{
        color: #E03D2F;
    }
    &.active{
        color: #E03D2F;
        font-weight: 600;
        background: #FFE4E4;
    }
}
.law-category{
    .law-category-header{
        border-bottom: 1px solid #E9E9E9;
        span{
            padding-bottom: 24px;
            margin-right: 64px;
            display: inline-block;
            font-size: 16px;
            line-height: 16px;
            cursor: pointer;
            color: #999;
            &:hover{
                color: #E60003;
            }
        }
        span.active{
            color: #E60003;
            position: relative;
            font-weight: 600;
            &::after{
                position: absolute;
                left: 16px;
                right: 16px;
                height: 4px;
                background: #E60003;
                bottom: 0;
                content: '';
            }
        }
    }
}
ul.lawList{
    display: flex;
    flex-wrap:wrap;
    li{
        position: relative;
        background: #fff;
        width: 282px;
        margin-right: 24px;
        margin-bottom: 24px;
        border-radius: 4px;
        padding: 16px 16px 24px;
        box-sizing: border-box;
        transition: all .4s ease-in-out;
        &:hover{
            cursor: pointer;
            box-shadow: 0px 2px 24px 0px rgba(128,54,54,0.1);
            .title{
                color: #E60003;
            }
        }
        &:nth-child(4n){
            margin-right: 0;
        }
        img{
            width: 100%;
            height: 140px;
            object-fit: contain;
            vertical-align: middle;
        }
    }
    .time{
        font-size: 14px;
        line-height: 14px;
        color: #999;
        margin-top: 16px;
    }
    .tag{
        height: 28px;
        border-radius: 4px 0px 16px 0px;
        position: absolute;
        left: 0;
        top: 0;
        color: #fff;
        font-size: 12px;
        line-height: 28px;
        padding: 0 8px;
    }
    .top{
        position: relative;
        p{
            width:218px;
            position: absolute;
            left: 50%;
            top: 50%;
            color: #fff;
            text-align: center;
            padding:0 16px;
            font-size:18px;
            transform: translate(-50%,-50%);
        }
    }
    .cls1{
        background: #E60003;
    }
    .cls2{
        background: #F97800;
    }
    .cls3{
        background: #F7A825;
    }
    .cls4{
        background: #1A72FF;
    }
    .cls5{
        background: #999999;
    }
    .top{
        background: rgba(0,0,0,.5);
    }
    .status{
        font-size: 16px;
        padding:8px 12px;
        border-radius: 4px;
        height: 32px;
        box-sizing: border-box;
        line-height: 1;
    }
    .title{
        font-size: 16px;
        line-height: 28px;
        height: 56px;
        color: #666;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* 显示的行数 */
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-top: 16px;
    }
}
.pagination{
    text-align: center;
    margin-top: 8px;
    ::v-deep {
        .el-pager li{
            margin: 0 5px;
            border-radius: 6px;
        }
        .el-pagination .btn-prev,
        .el-pagination .btn-next{
            border-radius: 6px;
            padding: 0;
        }
        .el-pagination .btn-next{
            margin-left: 15px;
        }
        .el-pager li.active{
            background: #E60003;
            color: #fff;
        }
    }
}
</style>